body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  margin: 0;
}

* {
  font-family: sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

.svgFace {
  position: fixed;
  top: 10%;
  left: 20%;
  right: 20%;
  max-width: 250px;
}

.c-hero__block {
  position: absolute;
  top: 10%;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 450px;
  height: 250px;
  color: #333;
  background: #fcfcfc;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  max-width: 90%;
  max-height: 90%;
  opacity: 0;
  overflow: hidden;
}

.c-hero__block .name {
  display: block;
  font-size: 30px;
  font-weight: 800;
  padding-left: 7px;
  padding-top: 10px;
  line-height: 1em;
}

.c-hero__block .name > span {
  overflow: hidden;
  display: inline-block;
}

.c-hero__block .name > span:not(.char1):not(.char6) {
  opacity: 0;
  max-width: 0;
}

.c-hero__block__contact {
  padding-left: 9%;
}

.c-hero__block__contact > p {
  margin: 0 0 10px;
}

.c-hero__block__contact > * {
  opacity: 0;
  display: block;
  line-height: 27px;
}

.c-hero__block__contact footer {
  position: absolute;
  right: 7%;
  bottom: 7%;
  text-align: right;
  font-size: 12px;
  color: #666;
}

.c-hero__block__contact footer > div{
  text-align: right;
}

.c-hero__block__contact footer > * {
  margin-right: 10px;
}

.c-hero__block__contact footer i {
  font-size: 28px;
}

.c-hero__block__contact footer i {
  font-size: 28px;
}

footer a:hover {
  opacity: 0.8;
}

.fb-logo {
  display: inline-block;
  width: 32px;
  height: 32px;
  margin-top: 8px;
  background: url('/facebook_logo.png');
  background-size: 32px;
}

.linkedin-logo {
  display: inline-block;
  width: 32px;
  height: 32px;
  margin-top: 8px;
  background: url('/linkedin_logo.png');
  background-size: 32px;
}

.brunch-logo {
  display: inline-block;
  width: 32px;
  height: 32px;
  margin-top: 8px;
  background: url('/brunch_logo.png');
  background-size: 32px;
}
